<template>
    <div class="view-container padded" id="routeRecorder">

        <div style="display: flex; color: gray; margin-bottom: 1em;" >
            <div class="flex-1" style="color: black;">
                <h1>Trasa {{ currentId }}</h1>
            </div>
            <div @click="$router.push({path: '/'})">
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="icon" fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                </svg>
            </div>
        </div>

        <div class="milestones" v-if="milestones.length">
            <div v-for="(milestone, index) in milestones"
                 :key="index"
                 class="milestone"
            >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </div>
                <div class="flex-1">
                    <div v-text="milestone.title" class="milestone__title"></div>
                    <div v-text="formatDateTime(milestone.timestamp)" class="milestone__date"></div>
                    <div class="milestone__date">{{ milestone.coords.latitude}}, {{ milestone.coords.longitude }}</div>
                </div>
                <div>
                    <svg
                        @click="removeRecord(index)"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon milestone__delete"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </div>
            </div>
        </div>

        <button
            @click="recordPosition()"
            class="btn btn-lg btn-primary btn-block">
            Přidat nový bod
        </button>
    </div>
</template>
<script>
import IdbService from "../services/IdbService";
import StoreContainer from '../store.js';

function cloneAsObject(obj) {
    if (obj === null || !(obj instanceof Object)) {
        return obj;
    }
    var temp = (obj instanceof Array) ? [] : {};
    // ReSharper disable once MissingHasOwnPropertyInForeach
    for (var key in obj) {
        temp[key] = cloneAsObject(obj[key]);
    }
    return temp;
}

export default {
    data() {
        return {
            milestones: [],
            store: StoreContainer.data,
            currentId: false
        }
    },

    mounted() {
        this.currentId = this.$route.params.id;

        this.loadRecord()
    },

    watch: {
        '$route.params.index': {
            handler(index) {
                this.loadRecord(index)
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        loadRecord() {
            IdbService.loadRecord(this.currentId).then((data) => {
                if(typeof data !== 'undefined') {
                    this.milestones = JSON.parse(data.milestones)
                }
            });
        },
        recordPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    position = cloneAsObject(position)

                    let text = 'Bod ' + parseInt(this.milestones.length + 1);

                    if(this.milestones.length === 0) {
                        text = 'Začátek cesty';
                    }

                    this.milestones.push({
                        title: text,
                        timestamp:  new Date().valueOf(),
                        coords: position.coords
                    })

                    IdbService.saveRecord(this.milestones, this.currentId)

                });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        },
        removeRecord(index) {
            this.milestones.splice(index, 1)

            IdbService.saveRecord(this.milestones, this.store.currentId)
        },
        formatDateTime(timestamp) {

            let date = new Date()

            date.setTime(timestamp);

            return date.toLocaleDateString(
                'cs-CZ',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                }
            ) + ' ' + date.toLocaleTimeString(
                'cs-CZ'
            );
        },
    }
}
</script>
<style>
.view-container.padded {
    padding: 0 1em 0 1em;
}

#routeRecorder .icon {
    width: 1.7em;
    height: 1.7em;
}

.milestones {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-bottom: 2em;
}

.milestone {
    display: flex;
    gap: 1em;
}

.milestone__title {
    font-weight: bold;
}

.milestone__date{
    color: gray;
}

.milestone__delete {
    color: grey;
}

.milestone__delete:hover {
    color: red;
}

</style>